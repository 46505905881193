
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MMultiSelectBox from "@/components/form/MMultiSelectBox.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { Classroom } from "@/entities/classroom";
import { TutorAuthority } from "@/entities/tutor";
import { deleteTutor, TutorMeta, updateTutor } from "@/api/tutor";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MSelectBox,
    MTextField,
    MMultiSelectBox
  },
  emits: ["close"],
  props: {
    tutor: Object,
    editTutor: Object,
    editTutorEmail: String,
    data: Object,
    classrooms: Array
  }
})
export default class MsEditModal extends Vue {
  name = "";
  authorityText = "一般";
  authorityTexts: string[] = [];
  selectClassroomIds: string[] = [];
  tutor!: TutorMeta;
  editTutor!: TutorMeta;
  editTutorEmail!: string;
  classrooms: Classroom[] = [];

  get isSchool(): boolean {
    return store.state.isSchool;
  }

  get selectClassrooms(): Classroom[] {
    return this.classrooms.filter(item =>
      this.selectClassroomIds.includes(item.ref.id)
    );
  }

  get authority(): TutorAuthority {
    if (["管理者", "ICT担当者"].includes(this.authorityText)) {
      return "admin";
    } else if (this.authorityText === "責任者") {
      return "supervisor";
    } else if (["担当者", "先生"].includes(this.authorityText)) {
      return "employee";
    } else {
      return "general";
    }
  }

  get classroomsPlaceholder(): string {
    let text = `${this.selectClassrooms.length}教室`;
    if (this.selectClassrooms.length === 0) {
      return text;
    }
    text += ": ";
    text += this.selectClassrooms.map(item => item.data.name).join(", ");
    return text;
  }

  get validName() {
    return this.name.length > 0;
  }

  get validData() {
    return this.validName;
  }

  changeClassroom(id: string) {
    if (this.selectClassroomIds.includes(id)) {
      this.selectClassroomIds = this.selectClassroomIds.filter(
        item => item !== id
      );
    } else {
      this.selectClassroomIds.push(id);
    }
  }

  async update() {
    if (!this.validData) {
      return;
    }

    store.commit("START_LOADING", "更新中...");

    try {
      await updateTutor(this.editTutor.main.ref.id, {
        name: this.name,
        authority: this.authority,
        classrooms:
          this.authority === "general" ||
          this.authority === "employee" ||
          this.authority === "supervisor"
            ? this.selectClassroomIds
            : []
      });
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update tutor"
      );
      return;
    }

    this.$router.go(0);
  }

  async deleteTutor() {
    const confirmRes = confirm(
      "スタッフを本当に削除しますか？一度削除したスタッフを復元することはできません"
    );
    if (!confirmRes) {
      return;
    }

    store.commit("START_LOADING", "削除中...");
    try {
      await deleteTutor(this.editTutor.main.ref);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete tutor"
      );
      return;
    }

    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.name = this.editTutor.main.data.name;

    if (this.isSchool) {
      this.authorityText = "先生";
      if (this.editTutor.config.data.authority === "admin") {
        this.authorityText = "ICT担当者";
      } else if (this.editTutor.config.data.authority === "employee") {
        this.authorityText = "先生";
      }
      this.selectClassroomIds = this.editTutor.config.data.classrooms.map(
        item => item.id
      );
      if (this.tutor.config.data.authority === "owner") {
        this.authorityTexts = ["先生", "ICT担当者"];
      } else if (this.tutor.config.data.authority === "admin") {
        this.authorityTexts = ["先生"];
      } else if (this.tutor.config.data.authority === "supervisor") {
        this.authorityTexts = ["先生"];
      }
    } else {
      this.authorityText = "一般";
      if (this.editTutor.config.data.authority === "admin") {
        this.authorityText = "管理者";
      } else if (this.editTutor.config.data.authority === "supervisor") {
        this.authorityText = "責任者";
      } else if (this.editTutor.config.data.authority === "employee") {
        this.authorityText = "担当者";
      } else {
        this.authorityText = "一般";
      }
      this.selectClassroomIds = this.editTutor.config.data.classrooms.map(
        item => item.id
      );
      if (this.tutor.config.data.authority === "owner") {
        this.authorityTexts = ["一般", "担当者", "責任者", "管理者"];
      } else if (this.tutor.config.data.authority === "admin") {
        this.authorityTexts = ["一般", "担当者", "責任者"];
      } else if (this.tutor.config.data.authority === "supervisor") {
        this.authorityTexts = ["一般", "担当者"];
      } else {
        this.authorityTexts = ["一般"];
      }
    }
  }
}
