
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MMultiSelectBox from "@/components/form/MMultiSelectBox.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { Classroom } from "@/entities/classroom";
import { TutorAuthority } from "@/entities/tutor";
import { registerTutor, TutorMeta } from "@/api/tutor";
import { saveErrorLog } from "@/api/error";
import { exportToShiftJisCsv, readAsText } from "@/utils/csv";

@Options({
  components: {
    MBaseModal,
    MButton,
    MSelectBox,
    MTextField,
    MMultiSelectBox,
    MIcon
  },
  emits: ["close"],
  props: {
    tutor: Object,
    classrooms: Array
  }
})
export default class MsAddModal extends Vue {
  DEFAULT_PASSWORD = "12345678";
  name = "";
  enteredName = false;
  email = "";
  enteredEmail = false;
  password = "";
  authorityText = "一般";
  authorityTexts: string[] = [];
  selectedClassroomIds: string[] = [];
  tutor!: TutorMeta;
  classrooms: Classroom[] = [];

  get isSchool() {
    return store.state.isSchool;
  }

  get authorityMap(): Partial<Record<TutorAuthority, string>> {
    return this.isSchool
      ? {
          admin: "管理職",
          employee: "先生"
        }
      : {
          admin: "管理者",
          supervisor: "責任者",
          employee: "担当者",
          general: "一般"
        };
  }

  get classroomMap(): Record<string, string> {
    return store.state.classrooms
      .filter(r => r.ref.parent?.parent?.id === store.state.school?.ref.id)
      .reduce((acc: Record<string, string>, cur) => {
        acc[cur.ref.id] = cur.data.name;
        return acc;
      }, {});
  }

  get selectClassrooms(): Classroom[] {
    return this.classrooms.filter(item =>
      this.selectedClassroomIds.includes(item.ref.id)
    );
  }

  get authority(): TutorAuthority {
    if (["管理者", "ICT担当者"].includes(this.authorityText)) {
      return "admin";
    } else if (this.authorityText === "責任者") {
      return "supervisor";
    } else if (["担当者", "先生"].includes(this.authorityText)) {
      return "employee";
    } else {
      return "general";
    }
  }

  get classroomsPlaceholder(): string {
    let text = `${this.selectClassrooms.length}教室`;
    if (this.selectClassrooms.length === 0) {
      return text;
    }
    text += ": ";
    text += this.selectClassrooms.map(item => item.data.name).join(", ");
    return text;
  }

  validateAuthorityName(val: string): boolean {
    if (!val) return false;
    return Object.values(this.authorityMap).includes(val);
  }

  validateClassroomNames(classroomNames: string[]): boolean {
    if (!classroomNames.length) return true;
    return classroomNames.every(name =>
      Object.values(this.classroomMap).includes(name)
    );
  }

  validateName(name: string) {
    return name.length > 0;
  }
  get validName() {
    return this.validateName(this.name);
  }

  validateEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  get validEmail(): boolean {
    return this.validateEmail(this.email);
  }

  validatePassword(password: string) {
    return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(password);
  }
  get validPassword(): boolean {
    return this.validatePassword(this.password);
  }

  get validData() {
    return this.validName && this.email && this.validPassword;
  }

  get allEntered() {
    return this.enteredName && this.enteredEmail;
  }

  changeClassroom(id: string) {
    if (this.selectedClassroomIds.includes(id)) {
      this.selectedClassroomIds = this.selectedClassroomIds.filter(
        item => item !== id
      );
    } else {
      this.selectedClassroomIds.push(id);
    }
  }

  allEnter() {
    this.enteredName = true;
    this.enteredEmail = true;
  }

  async register() {
    this.allEnter();

    if (!this.validData) {
      return;
    }

    store.commit("START_LOADING", "スタッフ登録中...");

    try {
      await registerTutor(
        this.name.trim(),
        this.email,
        this.password,
        this.authority,
        this.authority === "general" ||
          this.authority === "employee" ||
          this.authority === "supervisor"
          ? this.selectedClassroomIds
          : []
      );
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register tutor"
      );
      return;
    }

    this.$router.go(0);
  }

  downloadCsvTemplate() {
    const headers: { key: string; displayKey: string }[] = [
      { key: "name", displayKey: "スタッフ名" },
      { key: "email", displayKey: "メールアドレス (重複不可)" },
      { key: "authority", displayKey: "権限" },
      {
        key: "classroomNames",
        displayKey: "確認権限を持つ教室名一覧（コンマ区切り）"
      }
    ];
    exportToShiftJisCsv(
      headers.map(_ => _.displayKey).join(","),
      "スタッフ登録用紙"
    );
  }

  validateStaffData(staffData: {
    name: string;
    email: string;
    authority: string;
    classroomNames: string[];
  }) {
    const { name, email, authority, classroomNames } = staffData;

    let validEmail = false;
    let validName = false;
    let validAuthority = false;
    let validClassroomNames = false;

    if (this.validateName(name)) {
      validName = true;
    }

    if (this.validateEmail(email)) {
      validEmail = true;
    }
    if (this.validateAuthorityName(authority)) {
      validAuthority = true;
    }
    if (this.validateClassroomNames(classroomNames)) {
      validClassroomNames = true;
    }

    return { validName, validEmail, validAuthority, validClassroomNames };
  }

  async handleCsvRegistration(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) return;
    if (!event.target.files || event.target.files.length === 0) return;
    const text = await readAsText(event.target.files[0], "Shift_JIS");
    event.target.value = ""; //input要素のfile入力をリセット

    if (typeof text !== "string") {
      throw new Error(
        "ファイルを正常に読み込むことができませんでした。CSVファイルのデータが正しいかどうか確認してください"
      );
    }

    const textToArray = text
      .replace(/"/g, "")
      .split("\n")
      .map(line => line.trim())
      .filter(Boolean);

    const filtered = textToArray.filter((_, i) => i > 0).filter(Boolean);
    if (!filtered.length) return;
    const rows = filtered.map(data =>
      data
        .split(",")
        .filter(Boolean)
        .map(data => data.trim())
    );
    const parsedStaffDatas = rows.reduce(
      (
        acc: {
          name: string;
          email: string;
          authority: string;
          classroomNames: string[];
        }[],
        cur: string[]
      ) => {
        // 0,1,2までは1対1のデータ、3以降はすべて教室nameの配列化する
        const classroomNames: string[] = cur.filter((_, i) => i > 2);
        const staff = {
          name: cur[0],
          email: cur[1],
          authority: cur[2],
          classroomNames
        };
        return [...acc, staff];
      },
      []
    );

    const usedEmails: string[] = [];
    for (const [index, data] of parsedStaffDatas.entries()) {
      //個々のチェック
      const validationResult = this.validateStaffData(data);
      const invalidKeys: string[] = [];
      Object.entries(validationResult).forEach(([key, val]) => {
        if (!val) invalidKeys.push(key);
      });

      if (invalidKeys.length > 0) {
        const text = invalidKeys
          .join(",")
          .replace("validName", "名前")
          .replace("validEmail", "メールアドレス")
          .replace("validAuthority", "権限")
          .replace("validClassroomNames", "教室名");
        alert(`${index + 1}番目のデータで${text}に誤りがあります。`);
        return;
      }

      //重複チェック
      const { email } = data;
      if (usedEmails.includes(email)) {
        alert(
          `登録しようとしているデータ内で、メールアドレスに重複があります。` +
            "\n" +
            `重複しているメールアドレス: ${email}`
        );
        return;
      } else {
        usedEmails.push(email);
      }
    }

    try {
      store.commit("START_LOADING", "スタッフ登録中...");

      await this.registerStaffViaCsv(parsedStaffDatas);
      this.$router.go(0);
    } catch (e) {
      alert("一部スタッフの登録に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
    }
  }

  async registerStaffViaCsv(
    datas: {
      name: string;
      email: string;
      authority: string;
      classroomNames: string[];
    }[]
  ) {
    const results = await Promise.allSettled(
      datas.map(async (data, index) => {
        await new Promise(_ => setTimeout(_, 500 * index));
        const { name, email, authority: _authority, classroomNames } = data;
        const authority = Object.entries(this.authorityMap).find(
          ([, val]) => val === _authority
        )?.[0] as TutorAuthority;
        const classroomIds: string[] =
          classroomNames.length > 0
            ? classroomNames.map(name => {
                return Object.entries(this.classroomMap).find(
                  ([, val]) => val === name
                )?.[0] as string;
              })
            : [];

        return registerTutor(
          name.trim(),
          email,
          this.DEFAULT_PASSWORD,
          authority,
          authority === "general" ||
            authority === "employee" ||
            authority === "supervisor"
            ? classroomIds
            : []
        );
      })
    );
    const failures: { name: string; error: string }[] = [];
    let successes = 0;
    results.forEach((result, i) => {
      if (result.status === "rejected") {
        failures.push({
          name: datas[i].name,
          error: result.reason.message ?? "unknown error"
        });
      } else {
        successes++;
      }
    });
    const text = `
成功件数: ${successes}件
失敗件数: ${failures.length}件

【失敗詳細】
${failures.map(failure => `${failure.name} : ${failure.error}`).join("\n")}
`.trim();
    alert(text);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.password = this.DEFAULT_PASSWORD;
    this.authorityText = this.isSchool ? "先生" : "一般";

    if (!this.isSchool) {
      if (this.tutor.config.data.authority === "owner") {
        this.authorityTexts = ["一般", "担当者", "責任者", "管理者"];
      } else if (this.tutor.config.data.authority === "admin") {
        this.authorityTexts = ["一般", "担当者", "責任者"];
      } else if (this.tutor.config.data.authority === "supervisor") {
        this.authorityTexts = ["一般", "担当者"];
      } else {
        this.authorityTexts = ["一般"];
      }
    } else {
      if (this.tutor.config.data.authority === "owner") {
        this.authorityTexts = ["先生", "管理職"];
      } else if (this.tutor.config.data.authority === "admin") {
        this.authorityTexts = ["先生"];
      } else if (this.tutor.config.data.authority === "supervisor") {
        this.authorityTexts = ["先生"];
      }
    }
  }
}
